import React from "react";
import { Link } from "gatsby";

import Head from "../../components/head.js";

import Layout from "../../components/layout";

import "./about.css";

const PaintingsPage = () => {
  return (
    <Layout>
      <Head title="Paintings" />
      <h1>Paintings</h1>
      <p>
        Here is a selection of paintings. They are done with acrylic paint, and
        I typically use just my hands to make them (fingerpainting). They would
        most likely be classified as abstract expressionism. I sometimes think
        of them almost as portraits of states of mind or emotions. While I plan
        out the colors I'll use, the mixing and shapes are a more kind of
        discovered / accidental process.
      </p>
      <p>
        Prints and t shirts with my paintings on them are available on my online
        store <a href="https://benjifriedman.xyz">benjifriedman.xyz</a>. Let me
        know also if you would like to commission a piece. Feel free to contact
        me through{" "}
        <Link
          to="/contact"
          style={{ fontWeight: "normal", textDecoration: "underline" }}
        >
          the form
        </Link>{" "}
        with specifications about dimensions and colors.
      </p>

      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1639807499/fingerpainting/ello-optimized-59e8d870.jpg"
        alt="red with dots"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1596606361/fingerpainting/27.jpg"
        alt="abstract painting"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1596606009/fingerpainting/35.jpg"
        alt="green abstract painting"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1596606009/fingerpainting/33.jpg"
        alt="red abstract painting"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1596606010/fingerpainting/32.jpg"
        alt="blue and red abstract painting"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1596606006/fingerpainting/25.jpg"
        alt="turquoise abstract painting"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1596606004/fingerpainting/19.jpg"
        alt="chaotic abstract painting"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1596606004/fingerpainting/23.jpg"
        alt="blue abstract finger painting"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1596606004/fingerpainting/11.jpg"
        alt="magenta abstract finger painting"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1596605998/fingerpainting/3.jpg"
        alt="orange abstract finger painting"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1596605998/fingerpainting/2.jpg"
        alt="primary colors abstract finger painting"
      />
      <Link to="/media">
        <h3>Media</h3>
      </Link>
      <Link to="/">
        <h3>Home</h3>
      </Link>
    </Layout>
  );
};

export default PaintingsPage;
